$color: #007bff;

@import "bootstrap";
@import "../pagination";

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction:column;
}

.main {
  display: flex;
  flex-direction:column;
  justify-content:space-between;
  flex: 1;
}
.contenet {
  flex: 1;
}

a, .btn-link {
  text-decoration: none;
}